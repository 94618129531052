/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        var menuList, mobileToggle;
        mobileToggle = $('.nav-1');
        mobileClose = $('.nav-primary .close-icon');
        menuList = $('.nav-primary');

        mobileToggle.on("click", function (e) {
          e.preventDefault();
          menuList.slideToggle();
        });

        mobileClose.on("click", function (e) {
          e.preventDefault();
          menuList.slideToggle();
        });

        var formContainer, formToggle, formClose;
        formContainer = $('.interior_consult_form');
        formToggle = $('.form-1');
        formClose = $('.form-2');

        formToggle.on("click", function (e) {
          e.preventDefault();
          formContainer.animate({ width: 'toggle' }, 350);
        });

        formClose.on("click", function (e) {
          e.preventDefault();
          formContainer.animate({ width: 'toggle' }, 0);
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
        $(".hero__slider").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          pauseOnFocus: true,
          pauseOnHover: true,
          autoplay: true,
          autoplaySpeed: 5000
        });
        // $('.testimonial__entry > img').on('click', function (e) {
        //   e.preventDefault();
        //   $(this).siblings('.testimonial__text').toggleClass('testimonial__active');
        // })
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
